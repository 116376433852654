






































































































































import Vue from 'vue'
import { IMapLayer } from './types'
import { FormMixin } from '@/components/Mixins'

const baseLayer: IMapLayer = {
  id: null,
  api_key: null,
  attribution: null,
  created_at: null,
  name: null,
  offline: null,
  path: null,
  url: null,
  seeded: false,
  path_template: null,
  format: null,
  updated_at: null,
  max_zoom: null,
  fallback: null
}

const props = {
  apiCallInProgress: {
    type: Boolean,
    default: false
  },
  error: {
    type: Error,
    default: null
  },
  mapNames: {
    type: Array,
    default: () => []
  },
  active: {
    type: Boolean,
    default: false
  }
}

export default Vue.extend({
  name: 'AddOnlineMapLayer',
  props,
  mixins: [FormMixin],
  data() {
    return {
      isFallback: false,
      maxZoom: 18,
      layerName: null,
      mapAttribution: null,
      mapUrl: null,
      formValid: false,
      focusedInput: 0
    }
  },
  async beforeMount() {
    this.$store.commit('maps/SET_ERROR', null)
    this.$store.commit('maps/SET_STATUS', null)
  },
  mounted() {
    document.addEventListener('keyup', this.onEnterKeyup)
    this.focusOnInput(this.focusedInput)
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.onEnterKeyup)
  },
  methods: {
    focusOnInput(index) {
      setTimeout(() => {
        this.$refs[`input-${index}`].focus()
      }, 250)
    },
    onEnterKeyup(event) {
      if (!this.formValid && event.key === 'Enter') {
        this.focusedInput = (this.focusedInput + 1) % 3
        this.focusOnInput(this.focusedInput)
      }

      if (this.formValid && event.key === 'Enter') {
        this.onSubmit()
      }
    },
    onCancel(): void {
      this.$emit('cancel')
    },
    onSubmit(): void {
      const layer = Object.assign(baseLayer, {
        name: this.layerName,
        offline: false,
        attribution: this.mapAttribution,
        url: this.mapUrl,
        max_zoom: this.maxZoom,
        fallback: this.isFallback
      })
      this.$emit('onSubmit', layer)
    },
    dismiss(v): void {
      if (!v) {
        this.$store.commit('maps/SET_ERROR', null)
        this.$store.commit('maps/SET_STATUS', null)
      }
    }
  },
  watch: {
    active: {
      handler: function(val) {
        if (val) {
          this.focusedInput = 0
          this.focusOnInput(this.focusedInput)
        } else {
          this.$refs['online-form'].resetValidation()
        }
      }
    }
  }
})
